@media print {
    .noprint{
        display: none !important;
    }
    @page{
        size: A4;
    }
    .main-wrapper{
        max-width: 100% !important;
        margin:0 !important;
    }
    body{
        -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important; 
    print-color-adjust: exact !important;
    }
}
.main-wrapper{
    max-width: 1300px;
    margin: 0 auto;
}
body{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color:#191919;
}
table{
    width: 100%;
}
.logo{
    width:100px;
}
.mt-40{
    margin-top: 40px;
}
.mt-30{
    margin-top: 30px;
}
.mt-20{
    margin-top: 20px;
}
.heading{
    font-size: 20px;
    color:#000;
}
.sub-heading{
    font-size: 16px;
}
.table-bordered,
.table-bordered td{
    border: 1px solid #777;
    border-collapse: collapse;
}
.table-bordered td{
    padding:6px 10px;
}
.first-child-40 tr td:first-child{
    max-width:40%;
    width:40%;
}
.first-child-40 tr td:last-child{
    max-width:60%;
    width:60%;
}
.first-child-60 tr td:first-child{
    max-width:40%;
    width:60%;
}
.first-child-60 tr td:last-child{
    max-width:40%;
    width:40%;
}
.medium{
    font-weight: 500;
}
.bg-green{
    background-color: #1cd61c;
}

.new-app-class{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    margin-right: 20px;
    
}

.new-app-class img{
   width: 150px;
}

.aadhar-photo {
    width: 200px;
    border: 1px solid black;
    margin-right: 50px;
    margin-top: 10px;
}