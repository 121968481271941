
.app-new-auth-header{
    padding:10px 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .app-new-auth-header-icon{
        width: 80px;
    }
    a{ 
        height: 100%;
        font-weight: 500;
        img{
            margin-right: 8px;
        }
    }
}
.login-bg-image{
    background-image: url('../../../public/images/login-bg.jpg');   
}
.register-bg-image{
    background-image: url('../../../public/images/signup-bg.jpg');   
}
.app-new-auth-page{
    width:100%;
    min-height: calc(100vh - 84px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .app-new-auth-page-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:30px 0;
        .app-new-auth-page-card{
            max-width: 700px;
            width:100%;
            background: #FFFFFF;
            border-radius: 20px;
            padding: 20px;
            form{
                display: flex;
                flex-direction: column;
            }
            &.app-new-auth-page-card-tenant{
                max-width: 700px;
            }
        }
    }
}
.app-new-auth-page-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    p{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
    }
}

.app-login-form-field-group{
    padding:0;
    margin:20px 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    li:not(:last-child){
        margin-bottom: 25px;
    }
    .app-login-form-field{
        background: #ffffff;
        border:0;
        border-bottom: 1px solid #DBE5ED;
        border-radius: 0;
        text-align: left;
        color: #212121;
        padding:13px 0;
        width: 100%;
        font-size: 16px;
        outline: 0 !important;
    }
}
.app-auth-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .MuiButtonBase-root{
        background: linear-gradient(269.09deg, #3375F5 0.15%, #032FE9 99.85%);
        border-radius: 5px;
        font-weight: 700;
        font-size: 16px;
        text-transform: capitalize;
        padding: 8px 45px;
    }
}

.app-login-form-button-alt{
    font-weight: 500;
    color: #000;
    font-size: 16px;
    padding:0;
    margin:20px 0 0 0;
    align-self: center;
}
@media (max-width: 767px) {
    .app-new-auth-page .app-new-auth-page-wrapper .app-new-auth-page-card{
        padding:20px 10px;
    }
}
@media (min-width: 320px) and (max-width: 375px) {
   
    .app-new-auth-header a{
        .text-size-control{
            font-size: 11px;
            img{
                margin-right: 5px;
                width: 12px;
            }
        }
    } 

}