@import url('./pages/login.scss');
@import url('./pages/register.scss');
.w-100{
    width:100%;
}
a{
    color: #005aab;
    &:hover{
    color:#5eb3ff;
    }
}
.mt-10{
    margin-top: 10px;
}
.family-tenent{
    li{
        list-style: none;
    }
}
.app-warning-content{
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 2px;
    color: #ff0000;
    padding-left: 13px;
    img{
        width: 12px;
        margin-right: 4px;
    }
}
.ml-10{
    margin-left: 10px !important;
}

.fs-12{
   font-size: 12px;
}
.fs-14{
    font-size: 14px;
 }
 .fs-16{
    font-size: 16px;
 }

.app-table-wrapper{
    padding:20px;
}
.app-custom-table{
    box-shadow: 0 0 15px #eaeaea;
    .MuiTableHead-root{
        background: rgb(0 90 171 / 20%);
    }
}
.button-icon-img{
    width: 20px;
}
.add-bg-full{
    background-image: url('../../public/images/landing-page/banner-bg.svg');
    background-position:bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.clearfix{
    width: 100%;
    height: 100px;
}
.policy-statement{
    padding: 30px 0;
    h1{
        font-family: "Open Sans";
        font-style: normal;
        font-weight:  600;
        font-size: 28px;
        color: #252525;
    }
    h2{
        font-family: "Open Sans";
        font-style: normal;
        font-weight:  600;
        font-size: 24px;
        color: #252525;
    }
    h3{
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #252525;
    }
    p{
        font-weight: 400;
        font-size: 15px;
        color: #252525;
    }
    li{
        font-weight: 400;
        font-size: 15px;
        color: #252525;
    }
}
.app-new-dashboard-header{
    display: flex;
    align-items: center;
}
.app-no-tenant-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    img{
        max-width: 300px;
        width: 100%;
        object-fit: contain;
    }
    h3{
        font-family: "Open Sans";
        font-style: normal;
        font-weight:  600;
        font-size: 24px;
        color: #252525;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 0;
    }
}
.app-user-current-location{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .MuiButtonBase-root{
        img{
            width: 18px;
            margin-right: 6px;
        }
        text-transform: capitalize;
        padding:10px 30px;
        border-radius: 30px;
    }
}
@media screen and (max-width:425px) {
    .app-new-dashboard-header{
        .MuiButtonBase-root.MuiButton-root{
            font-size: 12px;
            min-width: unset;
            padding-left: 6px;
            padding-right: 6px;
        }
    }   
}
.app-custom-select .MuiSelect-select{
    padding-top: 15px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 14px;
}
.app-register-form-field{
    .MuiOutlinedInput-notchedOutline{
        border: 0;
        border-bottom: 1px solid #eaeaea;
        border-radius: 0;
    }
}
.app-login-form-field-group.family-tenant-modal{
    margin: 0 !important;
}
.close-icon-btn{
    position: absolute;
    top:10px;
    right:10px;
    img{
        width: 16px;
    }
}

.forgot-password{
    color: #2C78D2;
    margin: 0;
}
.grid-class{
    .app-view-stepper{
        display: flex;
        flex-direction: column;
        width: 100%;
        // padding-top: 20px;
        ul{
            display: flex;
            list-style: none;
            padding:15px;
            margin:0;
            border-radius: 10px;
            justify-content: space-around;
            position: relative;
            &::after{
                content: "";
                top:25px;
                left:120px;
                position: absolute;
                width: 65%;
                height: 1px;
                background-color: #6e99f5;
            }
            li{
                &:not(:last-child){
                    margin-right:10px;
                }
                display: flex;
                margin:30px 0 0 0;
                position: relative;
                &::after{
                    position: absolute;
                    top:-28px;
                    left:50%;
                    transform: translateX(-50%);
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:#ffffff;
                    background-color: #005aab;
                    font-size: 10px;
                    border-radius: 50%;
                    font-weight: 600;
                    z-index: 9;
                }
                &:nth-child(1)::after{
                    content: "1";
                }
                &:nth-child(2)::after{
                    content: "2";
                }
                &:nth-child(3)::after{
                    content: "3";
                }
                &:nth-child(4)::after{
                    content: "4";
                }
                .app-view-stepper-list-item-info{
                    display: flex;
                    flex-direction: column;
                    h6{
                        font-weight: 600;
                        color: #646464;
                        font-size: 12px;
                        padding: 0;
                        margin:0 0 3px 0;
                        text-align: center;
                    }
                    p{
                        text-align: center;
                        font-weight: 400;
                        color: #000;
                        font-size: 12px;
                        padding: 0;
                        margin:0;
                        max-width: 140px;
                    }
                }
            }
        }
    }
}



// .app-view-stepper{
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     padding-top: 20px;
//     ul{
//         display: flex;
//         list-style: none;
//         padding:15px;
//         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
//         margin:0;
//         border-radius: 10px;
//         justify-content: space-around;
//         position: relative;
//         border: 1px solid #eaeaea;
//         &::after{
//             content: "";
//             top:25px;
//             left:88px;
//             position: absolute;
//             width: 65%;
//             height: 1px;
//             background-color: #6e99f5;
//         }
//         li{
//             &:not(:last-child){
//                 margin-right:10px;
//             }
//             display: flex;
//             margin:30px 0 0 0;
//             position: relative;
//             &::after{
//                 position: absolute;
//                 top:-28px;
//                 left:50%;
//                 transform: translateX(-50%);
//                 width: 20px;
//                 height: 20px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 color:#ffffff;
//                 background-color: #005aab;
//                 font-size: 10px;
//                 border-radius: 50%;
//                 font-weight: 600;
//                 z-index: 9;
//             }
//             &:nth-child(1)::after{
//                 content: "1";
//             }
//             &:nth-child(2)::after{
//                 content: "2";
//             }
//             &:nth-child(3)::after{
//                 content: "3";
//             }
//             &:nth-child(4)::after{
//                 content: "4";
//             }
//             .app-view-stepper-list-item-info{
//                 display: flex;
//                 flex-direction: column;
//                 h6{
//                     font-weight: 600;
//                     color: #646464;
//                     font-size: 12px;
//                     padding: 0;
//                     margin:0 0 3px 0;
//                     text-align: center;
//                 }
//                 p{
//                     text-align: center;
//                     font-weight: 400;
//                     color: #000;
//                     font-size: 12px;
//                     padding: 0;
//                     margin:0;
//                     max-width: 140px;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: 767px){
//     .grid-class{
//     .app-view-stepper{
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//         padding-top: 20px;
//         ul{
//             display: flex;
//             flex-direction: column;
//             &::after{
//                 content: "";
//                 top:40px;
//                 left:30px;
//                 width: 1px;
//                 height: calc(100% - 120px);
//                 background-color: #6e99f5;
//             }
//             li{
//                 &:not(:last-child){
//                     margin-right:0;
//                 }
//                 margin:10px 0 10px 30px;
//                 &::after{
//                     top:-2px;
//                     left:-15px;
//                 }
//                 .app-view-stepper-list-item-info{
//                     h6{
//                         text-align: left;
//                     }
//                     p{
//                         text-align: left;
//                     }
//                 }
//             }
//         }
//     }
// }
// }


@media screen and (max-width: 767px){
    .grid-class{
    .app-view-stepper{

        ul{

            &::after{
                content: "";
                top:40px;
                left:30px;
                width: 1px;
                height: calc(100% - 120px);
                background-color: #6e99f5;
            }
         
        }
    }
}
}
