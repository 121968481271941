@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
html{
    scroll-behavior: smooth;
}
#services,
#about,
#contactus{
    position: relative;
    top:-120px;
}
.app-landing-page{
    .app-home-cta{
        background: linear-gradient(269.09deg, #3375F5 0.15%, #032FE9 99.85%);
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        text-transform: capitalize;
        padding: 8px 25px;
    }
    font-family: 'Open Sans', sans-serif;
    .app-landing-page-header{
        position: fixed;
        width: 100%;
        z-index: 99999;
        background-color: #ffffff;
        padding:5px 0;
        box-shadow: 0 0 10px #eaeaea;
        .app-landing-page-logo{
            width: 100px;
        }
        .navbar-light .navbar-nav .nav-item{
            &:not(:last-child){
                margin-right: 20px;
            }
            .nav-link{
                font-weight: 400;
                font-size: 16px;
                color: #000000;
            }
        }
       
    }
    .app-landing-page-banner{
        .scratch-graphic{
            position: absolute;
            bottom:-129px;
            left:0;
            width: 80px;
        }
        width: 100%;
        height:100%;
        background-image: url('../../../public/images/landing-page/banner-bg.svg');
        background-position:bottom right;
        background-repeat: no-repeat;
        background-size: 800px;
        position: relative;
        .app-landing-page-banner-left{
            height: calc(100vh - 78px);
            margin-top: 78px;
            display: flex;
            align-items: center;
        }
    }
    .app-landing-page-banner-left-content{
        display: flex;
        flex-direction: column;
        h3{
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 10px;
            color: #252525;
        }
        h1{
            color: #252525;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            margin-bottom: 20px;
        }
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: #252525;
            margin-bottom: 0;
        }
    }
    .app-landing-page-banner-left-content-cta{
        margin-top: 30px;
        p{
            margin-bottom: 10px;font-weight: 600;
            font-size: 16px;
        }
        .app-home-cta{
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    .app-landing-page-banner-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        img{
            max-width: 700px;
        }
    }
    .screen-your-tenants{
        position: relative;
        width: 100%;
        padding: 150px 0;
        .screen-your-tenants-left{
            img{
                width: 100%;
            }
        }
        .screen-your-tenants-right{
            h3{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                color: #252525;
                margin-bottom: 20px;
            }
            p{
                font-weight: 400;
                font-size: 15px;
                color: #252525;
                margin-bottom: 20px;
            }
            ul{
                padding:0;
                margin:0;
                list-style: none;
                display: flex;
                flex-direction: column;
                list-style: none;
                li{
                    position: relative;
                    font-weight: 400;
                    font-size: 16px;
                    color: #252525;
                    margin-top: 10px;
                    padding-left: 20px;
                    &::before{
                        content: "";
                        position: absolute;
                        left:0;
                        top:8px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: linear-gradient(269.09deg, #3375F5 0.15%, #032FE9 99.85%);
                    }
                }
            }
        }
        .screen-your-tenants-graphics-content{
            position: absolute;
            bottom:-5px;
            right:0;
            .blink-graphic{
                position: absolute;
                top:-30px;
                left: -40px;
                width: 30px;
            }
            .home-graphic{
                width: 180px;
            }
        }
    }
    .graphic-border{
        position: relative;
        background-image: url('../../../public/images/landing-page/graphic-border.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 30px;
        filter: grayscale(1);
    }
    .score-bigger-credit{
        padding: 60px 0;
        width: 100%;
    }
    .score-bigger-credit-heading{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        color: #252525;
        text-align: center;
        span{
            font-weight: bold;
        }
    }
    
    .landing-page-mobile-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }
    .landing-page-mobile-app{
        position: relative;
        width: 410px;
        img{
            width: 100%;
        }
    }
    .score-bigger-credit-para{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        p{
            max-width: 70%;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: #252525;
        }
    }
    .adding-ur-properties-heading{
        text-align: center;
        margin-bottom: 90px;
    }
    
    .adding-ur-properties-card{
        background: #070707;
        border-radius: 20px;
        padding:30px;
        position: relative;
    }
    .adding-ur-properties-card-content{
        h3{
            font-weight: 600;
            font-size: 30px;
            color: #FFFFFF;
            margin-bottom: 15px;
        }
        h4{
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;
            margin-bottom: 15px;
        }
        .app-home-cta{
            margin-top: 30px;
        }
    }
    .adding-ur-properties-card-savetime{
        margin-top: 30px;
        h4{
            font-weight: 600;
            font-size: 24px;
            color: #FFFFFF;
            margin-bottom: 6px;
        }
        p{
            font-weight: normal;
            font-size: 15px;
            color: #FFFFFF;
            margin-bottom: 0;
        }
    }
    .adding-ur-properties-preview{
        position: absolute;
        bottom:0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        img{
            width: 80%;
        }
    }
.scratch-graphic-adding-properties{
    position: absolute;
    top:0;
    left:0;
}
.adding-ur-properties{
    padding:80px 0;
    position: relative;
}
.landing-page-contact{
    padding:60px 0;
    background-image: url('../../../public/images/landing-page/contact-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.contact-heading{
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
}
.landing-page-contact-card{
    background-color: #ffffff;
    padding:50px 30px;
    border-radius: 20px;
}
.landing-page-contact-card-item{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4{
        font-weight: 600;
        font-size: 22px;
        color: #252525;
        text-align: center;
        margin-bottom: 15px;
    }
    p{
        font-size: 16px;
        text-align: center;
        color: #252525;
        margin-bottom: 10px;
    }
    a{
        font-weight: 700;
        text-align: center;
        font-size: 18px;
        color: #252525;
    }
}
.landing-page-contact-card-email{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img{
        width: 30px;
        margin-right: 20px;
    }
    span{
        font-weight: 600;
        font-size: 24px;
        color: #252525;
    }
}
.contact-address{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    p{
        font-weight: 400;
        font-size: 16px;
        color: #252525;
        margin:0;
        text-align: center;
    }
}
.divder{
    margin:0 10px;
}
.app-landing-footer-content{
    width: 100%;
    display: flex;
    padding:10px 0;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    justify-content: space-between;
    img{
        width: 100px;
    }
    ul{
        padding:0;
        margin:0;
        list-style:none;
        display: flex;
        li{
            margin-left: 20px;
            a{
                font-weight: 400;
                font-size: 15px;
                color: #000000;
            }
        }
    }
}
.app-landing-footer-highlight{
    display: flex;
    align-items: center;
    p{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin:0;
        span{
            font-size: 8px;
            vertical-align: text-top;
        }
    }
    h6{
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        margin: 2px 0 0 0;
    }
}
}
@media (min-width: 992px) {
    .landing-page-mobile-app-features{
        display: block !important;
        position: absolute;
        top:0;
        .landing-page-mobile-app-features-item{
            position: absolute;
            width: 300px;
            h5{
                font-weight: 500;
                font-size: 16px;
                color: #252525;
                margin-bottom: 20px;
            }
            p{
                font-weight: 400;
                font-size: 14px;
                color: #252525;
                margin-bottom: 0;
            }
            span{
                display: block;
                width: 450px;
                border-bottom: 1px solid rgba(77, 164, 101, 1);
                margin-top: 20px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: -3px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: rgb(77, 164, 101);
                }
            }
        }
        .landing-page-mobile-app-features-item1{
            top:0;
            left:-340px;
        }
        .landing-page-mobile-app-features-item2{
            top:380px;
            left:-340px;
        }
        .landing-page-mobile-app-features-item3{
            top:710px;
            left:-340px;
        }
        .landing-page-mobile-app-features-item4{
            top:250px;
            left:440px;
            p{
                text-align: right;
            }
            h5{
                text-align: right;
            }
            span{
                width: 400px;
                left: -100px;
                &::after{
                    left: 0;
                }
            }
        }
        .landing-page-mobile-app-features-item5{
            top:560px;
            left:440px;
            p{
                text-align: right;
            }
            h5{
                text-align: right;
            }
            span{
                width: 400px;
                left: -100px;
                &::after{
                    left: 0;
                }
            }
        }
        .landing-page-mobile-app-features-item6{
            top:830px;
            left:440px;
            p{
                text-align: right;
            }
            h5{
                text-align: right;
            }
            span{
                width: 400px;
                left: -100px;
                &::after{
                    left: 0;
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .app-landing-page .app-landing-page-banner-right img {
        max-width: 440px;
    }
    .app-landing-footer-highlight{
        flex-direction: column;
        align-items: flex-start !important;
        .ml-3{
            margin-left: 0 !important;
            margin-top: 10px;
        }
    }
}
@media screen and (max-width:767px) {
    .app-landing-page .app-landing-footer-content,
    .app-landing-page .app-landing-footer-content ul{
        flex-direction: column;
        align-items: unset !important;
    }
    .app-landing-page .app-landing-page-banner .app-landing-page-banner-left{
        height: unset;
        margin-top: 120px;
    }
    .app-landing-page .screen-your-tenants .screen-your-tenants-graphics-content .home-graphic{
        display: none;
    }
    .app-landing-page .screen-your-tenants{
        padding: 40px 0;
    }
    .app-landing-page .app-landing-page-banner-left-content h3 {
        font-size: 30px;
    }
    .app-landing-page .app-landing-page-banner-right img {
        max-width: 100%;
        margin-top: 10px;
    }
    .app-landing-page .adding-ur-properties{
        padding:40px 0;
    }
    .app-landing-page .adding-ur-properties-heading{
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
    }
    .screen-your-tenants-right{
        margin-top: 20px;
    }
    .app-landing-page .adding-ur-properties-heading{
        width: 100%;
    }
    .adding-ur-properties-preview{
        display: none !important;
    }
    .app-landing-page .contact-heading{
        margin-bottom: 20px;
        font-size: 30px;
    }
    .app-landing-page .landing-page-contact-card-email span{
        font-size: 20px;
    }
    .app-landing-footer-highlight{
        flex-direction: column;
        align-items: flex-start !important;
        .ml-3{
            margin-left: 0 !important;
            margin-top: 10px;
        }
    }
    .app-landing-page .app-landing-footer-content ul li{
        margin-left: 0;
    }
    .app-landing-page .app-landing-footer-content ul{
        margin-top:10px;
    }
    .scratch-graphic-adding-properties,
    .blink-graphic,
    .scratch-graphic{
        display: none !important;
    }
    .app-landing-page .score-bigger-credit-para p{
        max-width: 100%;
        text-align: left;
    }
    .app-landing-page .score-bigger-credit{
        padding:40px 0;
    }
    .score-bigger-credit-heading sup{
        font-size: 12px;
        top: -1.1em;
    }
}
@media (max-width: 991px) {
    .landing-page-mobile-app-features{
       .landing-page-mobile-app-features-item{
        margin-top: 20px;
        h5{
            font-weight: 600;
            font-size: 16px;
            color: #252525;
            margin-bottom: 10px;
        }
        p{
            font-weight: 400;
            font-size: 14px;
            color: #252525;
            margin-bottom: 0;
        }
       }
    }
}

.new-landing{
    height: calc(100% - 105px);
}



.seperator{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}